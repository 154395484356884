import logo from './logo.svg';
import dec1 from './static/elka1.png'
import dec2 from './static/elka2.png'
import dec3 from './static/elka3.png'
import dec4 from './static/org1.png'
import dec5 from './static/Palka.png'
import dec6 from './static/dec2.png'
import dec7 from './static/tar.png'
import dec8 from './static/g1.png'
import dec9 from './static/g2.png'

import mess1 from './static/Vector.png'
import mess2 from './static/Vector-1.png'
import mess3 from './static/Vector-2.png'

import blockImg1 from './static/block1.png'
import {useState} from "react";

const baseBoxes = [
    {
        name: 'Новогодний подарок 1',
        desc: 'Свеча ароматическая из 100% соевого воска в гипсовом подсвечнике. Набор для глинтвейна. Мешочек бежевый с вышивкой "Свеча".',
        compound: [
            {id: 0, name: 'Свеча ароматическая'},
            {id: 1, name: 'Набор для глинтвейна'},
            {id: 2, name: 'Мешочек бежевый с вышивкой'},
            {id: 3, name: ''},
        ],
        price: '1017',
        productionTime: '1 шт - 3д/ 10 шт - 5д',
        images: [
            require('./static/baseBoxes/Бокс 01/Бокс 01/бокс3.png'),
            require('./static/baseBoxes/Бокс 01/Бокс 01/IMG_8816.jpg'),
            require('./static/baseBoxes/Бокс 01/Бокс 01/IMG_8839.jpg'),
            require('./static/baseBoxes/Бокс 01/Бокс 01/IMG_8982.jpg'),
        ]
    },    {
        name: 'Новогодний подарок 2',
        desc: 'Аромасаше из натурального воска. Игрушка из смолы на выбор: сосульки, снежинка, коньки, ёлочка. Мешочек бежевый с вышивкой "Свеча".',
        compound: [
            {id: 0, name: 'Аромасаше'},
            {id: 1, name: 'Игрушка из смолы'},
            {id: 2, name: 'Мешочек бежевый с вышивкой'},
            {id: 3, name: ''},
            {id: 4, name: ''},
            {id: 5, name: ''},
        ],
        price: '1231',
        productionTime: '1 шт - 4д/ 10 шт - 13д',
        images: [
            require('./static/baseBoxes/Бокс 02/Бокс 02/бокс11.png'),
            require('./static/baseBoxes/Бокс 02/Бокс 02/IMG_8959.jpg'),
            require('./static/baseBoxes/Бокс 02/Бокс 02/IMG_8921.jpg'),
            require('./static/baseBoxes/Бокс 02/Бокс 02/IMG_8982.jpg'),
            require('./static/baseBoxes/Бокс 02/Бокс 02/IMG_1493.jpg'),
            require('./static/baseBoxes/Бокс 02/Бокс 02/IMG_8962.jpg'),
        ]
    },{
        name: 'Новогодний подарок 2к',
        desc: 'Аромасаше из натурального воска. Игрушка из смолы на выбор: сосульки, снежинка, коньки, ёлочка. Картонная коробка.',
        compound: [
            {id: 0, name: 'Аромасаше'},
            {id: 1, name: 'Игрушка из смолы'},
            {id: 2, name: 'Картонная коробка'},
            {id: 3, name: ''},
        ],
        price: '1098',
        productionTime: '1 шт - 4д/ 10 шт - 13д',
        images: [
            require('./static/baseBoxes/Бокс 02.к/Бокс 02.к/бокс28.png'),
            require('./static/baseBoxes/Бокс 02.к/Бокс 02.к/бокс29.png'),
            require('./static/baseBoxes/Бокс 02.к/Бокс 02.к/бокс30.png'),
            require('./static/baseBoxes/Бокс 02.к/Бокс 02.к/бокс31.png'),
        ]
    },{
        name: 'Новогодний подарок 3',
        desc: 'Шоколадная плитка " Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Плетёная игрушка на ёлку "Ёлочка". Мешочек бежевый без вышивки.',
        compound: [
            {id: 0, name: 'Шоколадная плитка'},
            {id: 1, name: 'Плетёная игрушка'},
            {id: 2, name: 'Мешочек бежевый без вышивки'},
            {id: 3, name: ''},
        ],
        price: '1284',
        productionTime: '1 шт - 2д/ 10 шт - 5д',
        images: [
            require('./static/baseBoxes/Бокс 03/Бокс 03/Слой 17.png'),
            require('./static/baseBoxes/Бокс 03/Бокс 03/IMG_8767-2.jpg'),
            require('./static/baseBoxes/Бокс 03/Бокс 03/IMG_8904.jpg'),
            require('./static/baseBoxes/Бокс 03/Бокс 03/IMG_8974.jpg'),
        ]
    },{
        name: 'Новогодний подарок 4',
        desc: 'Конфеты из разных видов шоколада без сахара, глютена и лактозы с декором из сублимированных ягод, фруктов, орехов. Плетёная игрушка на ёлку "Ёлочка". Мешочек бежевый с вышивкой "Свеча".',
        compound: [
            {id: 0, name: 'Конфеты'},
            {id: 1, name: 'Плетёная игрушка'},
            {id: 2, name: 'Мешочек бежевый с вышивкой'},
            {id: 3, name: ''},
        ],
        price: '1284',
        productionTime: '1 шт - 3д/ 10 шт - 4д',
        images: [
            require('./static/baseBoxes/Бокс 04/Бокс 04/бокс27.png'),
            require('./static/baseBoxes/Бокс 04/Бокс 04/IMG_8785.jpg'),
            require('./static/baseBoxes/Бокс 04/Бокс 04/IMG_8908.jpg'),
            require('./static/baseBoxes/Бокс 04/Бокс 04/IMG_8982.jpg'),
        ]
    },{
        name: 'Новогодний подарок 5',
        desc: 'Мелтс из соевого воска с ароматом мандарина. Флорентийское аромасаше из соевого воска в новогодней тематике. Натуральное мыло из растительных масел "Глинтвейн". Мешочек красный с вышивкой "Свеча". ',
        compound: [
            {id: 0, name: 'Мелтс'},
            {id: 1, name: 'Флорентийское аромасаше'},
            {id: 2, name: 'Натуральное мыло'},
            {id: 3, name: 'Мешочек красный с вышивкой'},
            {id: 4, name: ''},
        ],
        price: '1295',
        productionTime: '1 шт - 3д/ 10 шт - 5д',
        images: [
            require('./static/baseBoxes/Бокс 05/Бокс 05/бокс42.png'),
            require('./static/baseBoxes/Бокс 05/Бокс 05/IMG_8851.jpg'),
            require('./static/baseBoxes/Бокс 05/Бокс 05/IMG_8852.jpg'),
            require('./static/baseBoxes/Бокс 05/Бокс 05/IMG_8981.jpg'),
            require('./static/baseBoxes/Бокс 05/Бокс 05/IMG_8916.jpg'),
        ]
    },{
        name: 'Новогодний подарок 6',
        desc: 'Свеча из натурального пчелиного воска "Ёлочка". Мыло из соевого воска "Мандарин". Флорентийское аромасаше из соевого воска в новогодней тематике. Мешочек бежевый без вышивки.',
        compound: [
            {id: 0, name: 'Свеча'},
            {id: 1, name: 'Мыло'},
            {id: 2, name: 'Флорентийское аромасаше'},
            {id: 3, name: 'Мешочек бежевый без вышивки'},
            {id: 4, name: ''},
        ],
        price: '1370',
        productionTime: '1 шт - 2д/ 10 шт - 3д',
        images: [
            require('./static/baseBoxes/Бокс 06/Бокс 06/Слой 21.png'),
            require('./static/baseBoxes/Бокс 06/Бокс 06/IMG_8823.jpg'),
            require('./static/baseBoxes/Бокс 06/Бокс 06/IMG_8924.jpg'),
            require('./static/baseBoxes/Бокс 06/Бокс 06/IMG_8953.jpg'),
            require('./static/baseBoxes/Бокс 06/Бокс 06/IMG_8974.jpg'),
        ]
    },{
        name: 'Новогодний подарок 7',
        desc: 'Плетеная игрушка на ёлку "Машинка". Автопарфюм в стеклянном флаконе с деревянной крышкой. Мешочек красный с вышивкой "Свеча".',
        compound: [
            {id: 0, name: 'Плетеная игрушка'},
            {id: 1, name: 'Автопарфюм'},
            {id: 2, name: 'Мешочек красный с вышивкой'},
            {id: 3, name: ''},
        ],
        price: '1391',
        productionTime: '1 шт - 2д/ 10 шт - 7д',
        images: [
            require('./static/baseBoxes/Бокс 07/Бокс 07/бокс39.png'),
            require('./static/baseBoxes/Бокс 07/Бокс 07/IMG_1488.jpg'),
            require('./static/baseBoxes/Бокс 07/Бокс 07/IMG_8888.jpg'),
            require('./static/baseBoxes/Бокс 07/Бокс 07/IMG_8981.jpg'),
        ]
    },{
        name: 'Новогодний подарок 8',
        desc: 'Травяной сбор на основе Иван чая в новогоднем прозрачном пакете. Шоколадная плитка "Машинка с елочкой" с объемным рисунком и фигурками разного цвета. Мешочек красный с вышивкой "Шарик на ёлке".',
        compound: [
            {id: 0, name: 'Травяной сбор'},
            {id: 1, name: 'Шоколадная плитка'},
            {id: 2, name: 'Мешочек красный с вышивкой'},
            {id: 3, name: ''},
        ],
        price: '1519',
        productionTime: '1 шт - 4д/ 10 шт - 7д',
        images: [
            require('./static/baseBoxes/Бокс 08/Бокс 08/бокс9.png'),
            require('./static/baseBoxes/Бокс 08/Бокс 08/IMG_8762.jpg'),
            require('./static/baseBoxes/Бокс 08/Бокс 08/IMG_8780.jpg'),
            require('./static/baseBoxes/Бокс 08/Бокс 08/IMG_8973.jpg'),
        ]
    },
]
const midBoxes = [
    {
        name: 'Новогодний подарок 9',
        desc: 'Свеча из натуральной медовой вощины с хлопковым/джутовым фитилем. Плетеная игрушка на ёлку "Олень". Новогодний пряник. Шоколадная открытка "С Новым годом" из молочного бельгийского шоколада. Мешочек красный с вышивкой "Свеча". Наполнитель бумажный.',
        compound: [
            {id: 0, name: 'Свеча'},
            {id: 1, name: 'Плетеная игрушка'},
            {id: 2, name: 'Новогодний пряник'},
            {id: 3, name: 'Шоколадная открытка'},
            {id: 4, name: 'Мешочек красный с вышивкой'},
            {id: 5, name: 'Наполнитель бумажный'},
        ],
        price: '1637',
        productionTime: '1 шт - 3д/ 10 шт - 6д',
        images: [
            require('./static/midBoxes/Бокс 09/Бокс 09/бокс41.png'),
            require('./static/midBoxes/Бокс 09/Бокс 09/IMG_8733.jpg'),
            require('./static/midBoxes/Бокс 09/Бокс 09/IMG_8800.jpg'),
            require('./static/midBoxes/Бокс 09/Бокс 09/IMG_8828.jpg'),
            require('./static/midBoxes/Бокс 09/Бокс 09/IMG_8899.jpg'),
            require('./static/midBoxes/Бокс 09/Бокс 09/IMG_8981.jpg'),
        ]
    },    {
        name: 'Новогодний подарок 10',
        desc: 'Гипсовый подсвечник "Карусель" со свечей из натурального воска. Плетеная игрушка на ёлку "Олень".  Мелтс ароматический: мандарин / дух Рождества / мандарин и корица. Фруктовая и ягодная пастила. Мешочек бежевый без вышивки.',
        compound: [
            {id: 0, name: 'Гипсовый подсвечник'},
            {id: 1, name: 'Плетеная игрушка'},
            {id: 2, name: 'Мелтс'},
            {id: 3, name: 'Фруктовая и ягодная пастила'},
            {id: 4, name: 'Мешочек бежевый без вышивки'},
            {id: 5, name: ''},
        ],
        price: '1701',
        productionTime: '1 шт - 7д/ 10 шт - 8д',
        images: [
            require('./static/midBoxes/Бокс 10/Бокс 10/Слой 19.png'),
            require('./static/midBoxes/Бокс 10/Бокс 10/IMG_8770.jpg'),
            require('./static/midBoxes/Бокс 10/Бокс 10/IMG_8860.jpg'),
            require('./static/midBoxes/Бокс 10/Бокс 10/IMG_8896.jpg'),
            require('./static/midBoxes/Бокс 10/Бокс 10/IMG_8916.jpg'),
            require('./static/midBoxes/Бокс 10/Бокс 10/IMG_8974.jpg'),
        ]
    },{
        name: 'Новогодний подарок 11',
        desc: 'Конфеты (4 шт) в виде шаров из разных видов шоколада, без сахара, без глютена и лактозы. Набор для глинтвейна. Игрушка из эпоксидной смолы. Мешочек бежевый с вышивкой "Новый год".',
        compound: [
            {id: 0, name: 'Конфеты'},
            {id: 1, name: 'Набор для глинтвейна'},
            {id: 2, name: 'Игрушка из эпоксидной смолы'},
            {id: 3, name: 'Мешочек бежевый с вышивкой'},
            {id: 4, name: ''},
        ],
        price: '1766',
        productionTime: '1 шт - 4д/ 10 шт - 13д',
        images: [
            require('./static/midBoxes/Бокс 11/Бокс 11/бокс45.png'),
            require('./static/midBoxes/Бокс 11/Бокс 11/IMG_8789.jpg'),
            require('./static/midBoxes/Бокс 11/Бокс 11/IMG_8816.jpg'),
            require('./static/midBoxes/Бокс 11/Бокс 11/IMG_8966.jpg'),
            require('./static/midBoxes/Бокс 11/Бокс 11/IMG_8980.jpg'),
        ]
    },{
        name: 'Новогодний подарок 12',
        desc: 'Гипсовый подсвечник "Карусель" со свечей из натурального воска. Игрушка на ёлку белая "Ёлка в технике макраме". Мелтс ароматический: мандарин / дух Рождества / мандарин и корица. Фруктовая и ягодная пастила. Мешочек бежевый без вышивки.',
        compound: [
            {id: 0, name: 'Гипсовый подсвечник'},
            {id: 1, name: 'Игрушка на ёлку'},
            {id: 2, name: 'Фруктовая и ягодная пастила'},
            {id: 3, name: 'Мелтс'},
            {id: 4, name: 'Мешочек бежевый без вышивки'},
            {id: 5, name: ''},
        ],
        price: '1808',
        productionTime: '1 шт - 7д/ 10 шт - 8д',
        images: [
            require('./static/midBoxes/Бокс 12/Бокс 12/бокс23.png'),
            require('./static/midBoxes/Бокс 12/Бокс 12/IMG_8770.jpg'),
            require('./static/midBoxes/Бокс 12/Бокс 12/IMG_8860.jpg'),
            require('./static/midBoxes/Бокс 12/Бокс 12/IMG_8915.jpg'),
            require('./static/midBoxes/Бокс 12/Бокс 12/IMG_8916.jpg'),
            require('./static/midBoxes/Бокс 12/Бокс 12/IMG_8974.jpg'),
        ]
    },{
        name: 'Новогодний подарок 13',
        desc: 'Тарелка керамическая ручной работы с покрытием из пищевой глазури. Конфеты из разных видов шоколада без сахара, глютена и лактозы с декором из сублимированных ягод, фруктов, орехов. Мешочек красный с вышивкой "Колокольчики". ',
        compound: [
            {id: 0, name: 'Тарелка керамическая'},
            {id: 1, name: 'Конфеты'},
            {id: 2, name: 'Мешочек красный с вышивкой'},
            {id: 3, name: ''},
        ],
        price: '2140',
        productionTime: '1 шт - / 10 шт - 11д',
        images: [
            require('./static/midBoxes/Бокс 13/Бокс 13/бокс8.png'),
            require('./static/midBoxes/Бокс 13/Бокс 13/IMG_1924.jpg'),
            require('./static/midBoxes/Бокс 13/Бокс 13/IMG_8786.jpg'),
            require('./static/midBoxes/Бокс 13/Бокс 13/IMG_8944.jpg'),
        ]
    },{
        name: 'Новогодний подарок 14',
        desc: 'Плетёная игрушка на ёлку "Ёлочка". Свеча "Гранат" из пчелиного воска, ручная роспись. Мешочек красный с вышивкой "Свеча".',
        compound: [
            {id: 0, name: 'Плетёная игрушка'},
            {id: 1, name: 'Свеча'},
            {id: 2, name: 'Мешочек красный с вышивкой'},
            {id: 3, name: ''},
        ],
        price: '2140',
        productionTime: '1 шт - 2д / 10 шт - 6д',
        images: [
            require('./static/midBoxes/Бокс 14/Бокс 14/Слой 20.png'),
            require('./static/midBoxes/Бокс 14/Бокс 14/IMG_8904.jpg'),
            require('./static/midBoxes/Бокс 14/Бокс 14/IMG_8930.jpg'),
            require('./static/midBoxes/Бокс 14/Бокс 14/IMG_8981.jpg'),
        ]
    },{
        name: 'Новогодний подарок 15',
        desc: 'Игрушка на ёлку белая/зеленая (на выбор) "Ёлка в технике макраме". Свеча "Гранат" из пчелиного воска, ручная роспись. Мешочек красный с вышивкой "Свеча".',
        compound: [
            {id: 0, name: 'Игрушка на ёлку белая/зеленая (на выбор)'},
            {id: 1, name: 'Свеча'},
            {id: 2, name: 'Мешочек красный с вышивкой'},
            {id: 3, name: ''},
            {id: 4, name: ''},
        ],
        price: '2247',
        productionTime: '1 шт - 2д / 10 шт - 6д',
        images: [
            require('./static/midBoxes/Бокс 15/Бокс 15/бокс33.png'),
            require('./static/midBoxes/Бокс 15/Бокс 15/IMG_8911.jpg'),
            require('./static/midBoxes/Бокс 15/Бокс 15/IMG_8930.jpg'),
            require('./static/midBoxes/Бокс 15/Бокс 15/IMG_8915.jpg'),
            require('./static/midBoxes/Бокс 15/Бокс 15/IMG_8981.jpg'),
        ]
    },{
        name: 'Новогодний подарок 16',
        desc: 'Обложка на паспорт темно-коричневая из натуральной кожи. Брелок темно-коричневый. Плетеная игрушка на ёлку "Ёлочка".  Мешочек бежевый с вышивкой "Свеча". Наполнитель бумажный.',
        compound: [
            {id: 0, name: 'Обложка на паспорт'},
            {id: 1, name: 'Брелок'},
            {id: 2, name: 'Плетеная игрушка'},
            {id: 3, name: 'Мешочек бежевый с вышивкой'},
            {id: 4, name: 'Наполнитель бумажный'},
        ],
        price: '2461',
        productionTime: '1 шт - 2д / 10 шт - 4д',
        images: [
            require('./static/midBoxes/Бокс 16/Бокс 16/бокс44.png'),
            require('./static/midBoxes/Бокс 16/Бокс 16/IMG_8863.jpg'),
            require('./static/midBoxes/Бокс 16/Бокс 16/IMG_8887.jpg'),
            require('./static/midBoxes/Бокс 16/Бокс 16/IMG_8904.jpg'),
            require('./static/midBoxes/Бокс 16/Бокс 16/IMG_8982.jpg'),
        ]
    },{
        name: 'Новогодний подарок 17',
        desc: 'Обложка на паспорт светло-коричневая из натуральной кожи.  Брелок светло-коричневый. Плетеная новогодняя игрушка на ёлку светло-коричневая "Ёлочка".  Мешочек бежевый с вышивкой "Свеча". Наполнитель бумажный. ',
        compound: [
            {id: 0, name: 'Обложка на паспорт'},
            {id: 1, name: 'Брелок'},
            {id: 2, name: 'Плетеная игрушка'},
            {id: 3, name: 'Мешочек бежевый с вышивкой'},
            {id: 4, name: 'Наполнитель бумажный'},
        ],
        price: '2461',
        productionTime: '1 шт - 2д / 10 шт - 4д',
        images: [
            require('./static/midBoxes/Бокс 17/Бокс 17/бокс43.png'),
            require('./static/midBoxes/Бокс 17/Бокс 17/IMG_8867.jpg'),
            require('./static/midBoxes/Бокс 17/Бокс 17/IMG_8869.jpg'),
            require('./static/midBoxes/Бокс 17/Бокс 17/IMG_8908.jpg'),
            require('./static/midBoxes/Бокс 17/Бокс 17/IMG_8982.jpg'),
        ]
    },{
        name: 'Новогодний подарок 18',
        desc: 'Ключница темно-коричневая / светло-коричневая из натуральной кожи, фурнитура - латунь. Шоколадная плитка "Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Мешочек бежевый с вышивкой "Свеча".',
        compound: [
            {id: 0, name: 'Ключница'},
            {id: 1, name: 'Шоколадная плитка'},
            {id: 2, name: 'Мешочек бежевый с вышивкой'},
            {id: 3, name: ''},
            {id: 4, name: ''},
            {id: 5, name: ''},
        ],
        price: '2515',
        productionTime: '1 шт - 2д / 10 шт - 5д',
        images: [
            require('./static/midBoxes/Бокс 18/Бокс 18/бокс47.png'),
            require('./static/midBoxes/Бокс 18/Бокс 18/бокс46.png'),
            require('./static/midBoxes/Бокс 18/Бокс 18/IMG_8767-2.jpg'),
            require('./static/midBoxes/Бокс 18/Бокс 18/IMG_8872.jpg'),
            require('./static/midBoxes/Бокс 18/Бокс 18/IMG_8885.jpg'),
            require('./static/midBoxes/Бокс 18/Бокс 18/IMG_8982.jpg'),
        ]
    },
]
const luxBoxes = [
    {
        name: 'Новогодний подарок 19',
        desc: 'Ватная ёлочная игрушка "Гриб" из хлопковой и льняной ваты. Шоколадная плитка " Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Мешочек красный с вышивкой "Колокольчики".',
        compound: [
            {id: 0, name: 'Ватная ёлочная игрушка'},
            {id: 1, name: 'Шоколадная плитка'},
            {id: 2, name: 'Мешочек красный с вышивкой'},
            {id: 3, name: ''},
        ],
        price: '2675',
        productionTime: '1 шт - 6д / 10 шт - 16д',
        images: [
            require('./static/luxBoxes/Бокс 19/Бокс 19/бокс15.png'),
            require('./static/luxBoxes/Бокс 19/Бокс 19/IMG_8767-2.jpg'),
            require('./static/luxBoxes/Бокс 19/Бокс 19/IMG_8939.jpg'),
            require('./static/luxBoxes/Бокс 19/Бокс 19/IMG_1924.jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 20',
        desc: 'Пряник новогодний "Дед Мороз". Свеча "Неваляшка" из пчелиного и соевого воска. Мешочек красный с вышивкой "Игрушка на ёлке". ',
        compound: [
            {id: 0, name: 'Пряник новогодний'},
            {id: 1, name: 'Свеча'},
            {id: 2, name: 'Мешочек красный с вышивкой'},
            {id: 3, name: ''},
            {id: 4, name: ''},
        ],
        price: '2836',
        productionTime: '1 шт - 3д / 10 шт - 7д',
        images: [
            require('./static/luxBoxes/Бокс 20/Бокс 20/Слой 22.png'),
            require('./static/luxBoxes/Бокс 20/Бокс 20/IMG_8807.jpg'),
            require('./static/luxBoxes/Бокс 20/Бокс 20/IMG_8933.jpg'),
            require('./static/luxBoxes/Бокс 20/Бокс 20/IMG_8981.jpg'),
            require('./static/luxBoxes/Бокс 20/Бокс 20/IMG_8973.jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 21',
        desc: 'Плетеная новогодняя игрушка на ёлку "Машинка" бежевая, плоская, легкая. Автопарфюм в стеклянном флаконе с деревянной крышкой. Ключница светло-коричневая из натуральной кожи, фурнитура - латунь. Мешочек красный "Свеча". ',
        compound: [
            {id: 0, name: 'Плетеная новогодняя игрушка'},
            {id: 1, name: 'Автопарфюм'},
            {id: 2, name: 'Ключница'},
            {id: 3, name: 'Мешочек красный'},
            {id: 4, name: ''},
        ],
        price: '2836',
        productionTime: '1 шт - 2д / 10 шт - 4д',
        images: [
            require('./static/luxBoxes/Бокс 21/Бокс 21/бокс40.png'),
            require('./static/luxBoxes/Бокс 21/Бокс 21/IMG_1488.jpg'),
            require('./static/luxBoxes/Бокс 21/Бокс 21/IMG_8872.jpg'),
            require('./static/luxBoxes/Бокс 21/Бокс 21/IMG_8888.jpg'),
            require('./static/luxBoxes/Бокс 21/Бокс 21/IMG_8981.jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 22',
        desc: 'Ватная ёлочная игрушка "Гриб" из хлопковой и льняной ваты. Шоколадная плитка "Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Плетеная новогодняя игрушка коричневая на ёлку "Олень". Мешочек красный большой "Колокольчики".\n',
        compound: [
            {id: 0, name: 'Ватная ёлочная игрушка'},
            {id: 1, name: 'Шоколадная плитка'},
            {id: 2, name: 'Плетеная новогодняя игрушка'},
            {id: 3, name: 'Мешочек красный большой'},
            {id: 4, name: ''},
        ],
        price: '3103',
        productionTime: '1 шт - 6д / 10 шт - 16д',
        images: [
            require('./static/luxBoxes/Бокс 22/Бокс 22/бокс5.png'),
            require('./static/luxBoxes/Бокс 22/Бокс 22/IMG_8767-2.jpg'),
            require('./static/luxBoxes/Бокс 22/Бокс 22/IMG_8899.jpg'),
            require('./static/luxBoxes/Бокс 22/Бокс 22/IMG_8939.jpg'),
            require('./static/luxBoxes/Бокс 22/Бокс 22/IMG_1924.jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 22к',
        desc: 'Ватная ёлочная игрушка "Гриб" из хлопковой и льняной ваты. Шоколадная плитка "Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Плетеная игрушка на ёлку "Олень". Картонная коробка.',
        compound: [
            {id: 0, name: 'Ватная ёлочная игрушка'},
            {id: 1, name: 'Шоколадная плитка'},
            {id: 2, name: 'Плетеная новогодняя игрушка'},
            {id: 3, name: 'Картонная коробка'},
        ],
        price: '2574',
        productionTime: '1 шт - 6д / 10 шт - 16д',
        images: [
            require('./static/luxBoxes/Бокс 22.к/Бокс 22.к/бокс6.png'),
            require('./static/luxBoxes/Бокс 22.к/Бокс 22.к/IMG_8767-2.jpg'),
            require('./static/luxBoxes/Бокс 22.к/Бокс 22.к/IMG_8899.jpg'),
            require('./static/luxBoxes/Бокс 22.к/Бокс 22.к/IMG_8939.jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 23',
        desc: 'Ватная ёлочная игрушка "Девочка с собачкой" из хлопковой и льняной ваты. Шоколадная плитка "Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Мешочек красный с вышивкой "Колокольчики".',
        compound: [
            {id: 0, name: 'Ватная ёлочная игрушка'},
            {id: 1, name: 'Шоколадная плитка'},
            {id: 2, name: 'Мешочек красный с вышивкой'},
            {id: 3, name: ''},
        ],
        price: '3531',
        productionTime: '1 шт - 6д / 10 шт - 16д',
        images: [
            require('./static/luxBoxes/Бокс 23/Бокс 23/бокс14.png'),
            require('./static/luxBoxes/Бокс 23/Бокс 23/IMG_8767-2.jpg'),
            require('./static/luxBoxes/Бокс 23/Бокс 23/IMG_8948.jpg'),
            require('./static/luxBoxes/Бокс 23/Бокс 23/IMG_1924 (1).jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 24',
        desc: 'Ватная ёлочная игрушка "Девочка в костюме" из хлопковой и льняной ваты. Шоколадная плитка "Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Мешочек бежевый с вышивкой "Новый год".',
        compound: [
            {id: 0, name: 'Ватная ёлочная игрушка'},
            {id: 1, name: 'Шоколадная плитка'},
            {id: 2, name: 'Мешочек бежевый с вышивкой'},
            {id: 3, name: ''},
        ],
        price: '3531',
        productionTime: '1 шт - 6д / 10 шт - 16д',
        images: [
            require('./static/luxBoxes/Бокс 24/Бокс 24/бокс16.png'),
            require('./static/luxBoxes/Бокс 24/Бокс 24/IMG_8767-2.jpg'),
            require('./static/luxBoxes/Бокс 24/Бокс 24/IMG_8940.jpg'),
            require('./static/luxBoxes/Бокс 24/Бокс 24/IMG_8976.jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 25',
        desc: 'Ватная ёлочная игрушка "Девочка в костюме" из хлопковой и льняной ваты. Травяной сбор на основе Иван чая в новогоднем прозрачном пакете. Фруктовая и ягодная пастила трех видов. Мешочек бежевый с вышивкой "Новый год".',
        compound: [
            {id: 0, name: 'Ватная ёлочная игрушка из хлопковой и льняной ваты'},
            {id: 1, name: 'Травяной сбор на основе Иван чая в новогоднем прозрачном пакете'},
            {id: 2, name: 'Фруктовая и ягодная пастила трех видов'},
            {id: 3, name: 'Мешочек бежевый с вышивкой "Новый год"'},
            {id: 4, name: ''},
        ],
        price: '3017',
        productionTime: '1 шт - 6д / 10 шт - 16д',
        images: [
            require('./static/luxBoxes/Бокс 25/Бокс 25/бокс17.png'),
            require('./static/luxBoxes/Бокс 25/Бокс 25/IMG_8770.jpg'),
            require('./static/luxBoxes/Бокс 25/Бокс 25/IMG_8780.jpg'),
            require('./static/luxBoxes/Бокс 25/Бокс 25/IMG_8942.jpg'),
            require('./static/luxBoxes/Бокс 25/Бокс 25/IMG_8980.jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 26',
        desc: 'Ватные ёлочные игрушки "Дед Мороз и Снегурочка" из хлопковой и льняной ваты. Шоколадная плитка "Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Мешочек красный с вышивкой "Колокольчики".',
        compound: [
            {id: 0, name: '2 ватные ёлочные игрушки'},
            {id: 1, name: 'Шоколад «Тройка»'},
            {id: 2, name: 'Мешочек красный c вышивкой'},
            {id: 3, name: ''},
            {id: 4, name: ''},
        ],
        price: '3906',
        productionTime: '1 шт - 6д / 10 шт - 7д',
        images: [
            require('./static/luxBoxes/Бокс 26/Бокс 26/бокс13.png'),
            require('./static/luxBoxes/Бокс 26/Бокс 26/IMG_1924.jpg'),
            require('./static/luxBoxes/Бокс 26/Бокс 26/IMG_8767-2.jpg'),
            require('./static/luxBoxes/Бокс 26/Бокс 26/IMG_8936.jpg'),
            require('./static/luxBoxes/Бокс 26/Бокс 26/IMG_8938.jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 27',
        desc: 'Ватная ёлочная игрушка "Девочка с собачкой" из хлопковой и льняной ваты. Шоколадная плитка "Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Плетеная игрушка на ёлку "Олень". Мешочек красный с вышивкой "Колокольчики".',
        compound: [
            {id: 0, name: 'Ватная ёлочная игрушка'},
            {id: 1, name: 'Шоколадная плитка'},
            {id: 2, name: 'Плетеная игрушка'},
            {id: 3, name: 'Мешочек красный с вышивкой'},
            {id: 4, name: ''},
        ],
        price: '3959',
        productionTime: '1 шт - 6д / 10 шт - 16д',
        images: [
            require('./static/luxBoxes/Бокс 27/Бокс 27/бокс1.png'),
            require('./static/luxBoxes/Бокс 27/Бокс 27/IMG_8767-2.jpg'),
            require('./static/luxBoxes/Бокс 27/Бокс 27/IMG_8896.jpg'),
            require('./static/luxBoxes/Бокс 27/Бокс 27/IMG_8948.jpg'),
            require('./static/luxBoxes/Бокс 27/Бокс 27/IMG_1924.jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 27к',
        desc: 'Ватная ёлочная игрушка "Девочка с собачкой" из хлопковой и льняной ваты. Шоколадная плитка "Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Плетеная новогодняя игрушка светло-коричневая на ёлку "Олень". Коробка',
        compound: [
            {id: 0, name: 'Ватная ёлочная игрушка'},
            {id: 1, name: 'Шоколадная плитка'},
            {id: 2, name: 'Плетеная игрушка'},
            {id: 3, name: 'Коробка'},
        ],
        price: '3430',
        productionTime: '1 шт - 6д / 10 шт - 16',
        images: [
            require('./static/luxBoxes/Бокс 27.к/Бокс 27.к/бокс2.png'),
            require('./static/luxBoxes/Бокс 27.к/Бокс 27.к/IMG_8767-2.jpg'),
            require('./static/luxBoxes/Бокс 27.к/Бокс 27.к/IMG_8896.jpg'),
            require('./static/luxBoxes/Бокс 27.к/Бокс 27.к/IMG_8948.jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 28',
        desc: 'Ватная ёлочная игрушка "Девочка с собачкой" из хлопковой и льняной ваты. Шоколадная плитка "Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Плетеная игрушка на ёлку "Олень". Мешочек бежевый с вышивкой "Новый год".',
        compound: [
            {id: 0, name: 'Ватная ёлочная игрушка'},
            {id: 1, name: 'Шоколадная плитка'},
            {id: 2, name: 'Плетеная игрушка'},
            {id: 3, name: 'Мешочек бежевый с вышивкой'},
            {id: 4, name: ''},
        ],
        price: '3959',
        productionTime: '1 шт - 6д / 10 шт - 16',
        images: [
            require('./static/luxBoxes/Бокс 28/Бокс 28/бокс4.png'),
            require('./static/luxBoxes/Бокс 28/Бокс 28/IMG_8767-2.jpg'),
            require('./static/luxBoxes/Бокс 28/Бокс 28/IMG_8899.jpg'),
            require('./static/luxBoxes/Бокс 28/Бокс 28/IMG_8940.jpg'),
            require('./static/luxBoxes/Бокс 28/Бокс 28/IMG_8976.jpg'),
        ]
    },
    {
        name: 'Новогодний подарок 28к',
        desc: 'Ватная ёлочная игрушка "Девочка с собачкой" из хлопковой и льняной ваты. Шоколадная плитка "Тройка" из молочного шоколада Callebaut,  с сублимированными фруктами в ассортименте. Плетеная новогодняя игрушка коричневая на ёлку "Олень". Коробка.',
        compound: [
            {id: 0, name: 'Ватная ёлочная игрушка'},
            {id: 1, name: 'Шоколадная плитка'},
            {id: 2, name: 'Плетеная игрушка'},
            {id: 3, name: 'Коробка'},
        ],
        price: '3430',
        productionTime: '1 шт - 6д / 10 шт - 16',
        images: [
            require('./static/luxBoxes/Бокс 28.к/Бокс 28.к/бокс7.png'),
            require('./static/luxBoxes/Бокс 28.к/Бокс 28.к/IMG_8767-2.jpg'),
            require('./static/luxBoxes/Бокс 28.к/Бокс 28.к/IMG_8899.jpg'),
            require('./static/luxBoxes/Бокс 28.к/Бокс 28.к/IMG_8940.jpg'),
        ]
    },
]

function App() {
    const [baseBoxId, setBaseId] = useState(0)
    let maxBaseBoxItems = baseBoxes.length - 1
    const [selectedBaseBox, setSelectedBaseBox] = useState(baseBoxes[baseBoxId])
    const [selectedImage, setBaseImage] = useState(0)

    const [midBoxId, setMidId] = useState(0)
    let maxMidBoxItems = midBoxes.length - 1
    const [selectedMidBox, setSelectedMidBox] = useState(midBoxes[midBoxId])
    const [selectedMidImage, setMidImage] = useState(0)

    const [luxBoxId, setLuxId] = useState(0)
    let maxLuxBoxItems = luxBoxes.length - 1
    const [selectedLuxBox, setSelectedLuxBox] = useState(luxBoxes[luxBoxId])
    const [selectedLuxImage, setLuxImage] = useState(0)

    const changeBox = (setState, arr, id, setId, max, isRev = false) => {

        setBaseImage(0)
        setLuxImage(0)
        setMidImage(0)

        const newId = isRev
            ? (id === 0 ? max : id - 1)
            : (id === max ? 0 : id + 1);

        setId(newId);
        setState(arr[newId]);

    }

    return (
        <div className="App">
            <header>
                <h1 className="logo">REMESLO</h1>

                {/*<ul>*/}
                {/*    <li><a href="#">Главная</a></li>*/}
                {/*    <li><a href="#">Каталог</a></li>*/}
                {/*    /!*<li><a href="#">О нас</a></li>*!/*/}
                {/*    <li><a href="#">Контакты</a></li>*/}
                {/*</ul>*/}

                <img className={'dec1'} src={dec1} alt=""/>
            </header>

            <section className="block1">
                <div className="links">
                    <a href="https://t.me/stilkorzin"><img src={mess1} alt=""/></a>
                    <a href="https://vk.com/krutskikhmarina"><img src={mess2} alt=""/></a>
                    <a href="https://wa.me/qr/N2OONRVJBC36G1"><img src={mess3} alt=""/></a>
                </div>
                <div className="texts">
                    <h2 className="block-title">Подарочные наборы ручной работы от Липецких Мастеров</h2>
                    <p>Каждый набор — это уникальное сочетание традиционного мастерства и современных тенденций, которые подарят тепло и радость вашим близким.</p>
                    <a href="https://t.me/stilkorzin" className="btn">Заказать</a>
                </div>
                <img src={blockImg1} alt="" className="blockimg"/>
            </section>

            <section className="baseBoxes">
                <img src={selectedBaseBox.images[selectedImage]} className={'preview'} alt=""/>
                <aside>
                    <img src={dec2} className={'dec2'} alt=""/>
                    <h2 className="block-title">Базовые наборы</h2>

                    <div className="slider">
                        {/*<button onClick={() => {*/}
                        {/*    changeBox(setSelectedBaseBox, baseBoxes)*/}
                        {/*}}>*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"*/}
                        {/*         fill="FCF6ED">*/}
                        {/*        <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/>*/}
                        {/*    </svg>*/}
                        {/*</button>*/}
                        <button onClick={() => {
                            changeBox(setSelectedBaseBox, baseBoxes, baseBoxId, setBaseId, maxBaseBoxItems, true)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                 fill="FCF6ED">
                                <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/>
                            </svg>
                        </button>
                        <div className="box-info">
                            <h3>{selectedBaseBox.name}</h3>
                            <p>{selectedBaseBox.desc}</p>
                            <p className={'subtitle'}>Состав:</p>
                            <ul>
                                {selectedBaseBox.compound.map(c => (<li>{c.name}</li>))}
                            </ul>
                            <br/>
                            <p className="price"><strong>стоимость:</strong> {selectedBaseBox.price} руб.</p>
                            <p className="price"><strong>сроки изготовления:</strong> {selectedBaseBox.productionTime}</p>

                            <div className="subitems">
                                {selectedBaseBox.compound.map(c => (
                                    <a className={selectedImage === c.id && 'sel'} onClick={() => {
                                        setBaseImage(c.id)
                                    }}>{c.id + 1}</a>))}
                            </div>
                        </div>
                        <button onClick={() => {
                            changeBox(setSelectedBaseBox, baseBoxes, baseBoxId, setBaseId, maxBaseBoxItems)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                 fill="FCF6ED">
                                <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z"/>
                            </svg>
                        </button>

                    </div>
                </aside>
                <img src={dec3} className={'dec3'} alt=""/>
                <img src={dec4} className={'dec4'} alt=""/>
            </section>


            <section className="baseBoxes rev">
                <img src={selectedMidBox.images[selectedMidImage]} className={'preview'} alt=""/>
                <aside>
                    <img src={dec2} className={'dec2'} alt=""/>
                    <h2 className="block-title">Средние наборы</h2>

                    <div className="slider">
                        <button onClick={() => {
                            changeBox(setSelectedMidBox, midBoxes, midBoxId, setMidId, maxMidBoxItems, true)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                 fill="FCF6ED">
                                <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/>
                            </svg>
                        </button>
                        <div className="box-info">
                            <h3>{selectedMidBox.name}</h3>
                            <p>{selectedMidBox.desc}</p>
                            <p className={'subtitle'}>Состав:</p>
                            <ul>
                                {selectedMidBox.compound.map(c => (<li>{c.name}</li>))}
                            </ul>
                            <br/>
                            <p className="price"><strong>стоимость:</strong> {selectedMidBox.price} руб.</p>
                            <p className="price"><strong>сроки изготовления:</strong> {selectedMidBox.productionTime}</p>
                            <div className="subitems">
                                {selectedMidBox.compound.map(c => (
                                    <a className={selectedMidImage === c.id && 'sel'} onClick={() => {
                                        setMidImage(c.id)
                                    }}>{c.id + 1}</a>))}
                            </div>
                        </div>
                        <button onClick={() => {
                            changeBox(setSelectedMidBox, midBoxes, midBoxId, setMidId, maxMidBoxItems)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                 fill="FCF6ED">
                                <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z"/>
                            </svg>
                        </button>

                    </div>
                </aside>
                <img src={dec5} className={'dec3'} alt=""/>
                {/*<img src={dec4} className={'dec4'} alt=""/>*/}
            </section>


            <section className="baseBoxes">
                <img src={selectedLuxBox.images[selectedLuxImage]} className={'preview'} alt=""/>
                <aside>
                    <img src={dec6} className={'dec2'} alt=""/>
                    <h2 className="block-title">Люкс наборы</h2>

                    <div className="slider">
                        {/*<button onClick={() => {*/}
                        {/*    changeBox(setSelectedBaseBox, baseBoxes)*/}
                        {/*}}>*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"*/}
                        {/*         fill="FCF6ED">*/}
                        {/*        <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/>*/}
                        {/*    </svg>*/}
                        {/*</button>*/}
                        <button onClick={() => {
                            changeBox(setSelectedLuxBox, luxBoxes, luxBoxId, setLuxId, maxLuxBoxItems, true)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                 fill="FCF6ED">
                                <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/>
                            </svg>
                        </button>
                        <div className="box-info">
                            <h3>{selectedLuxBox.name}</h3>
                            <p>{selectedLuxBox.desc}</p>
                            <p className={'subtitle'}>Состав:</p>
                            <ul>
                                {selectedLuxBox.compound.map(c => (<li>{c.name}</li>))}
                            </ul>
                            <br/>
                            <p className="price"><strong>стоимость:</strong> {selectedLuxBox.price} руб.</p>
                            <p className="price"><strong>сроки изготовления:</strong> {selectedLuxBox.productionTime}</p>
                            <div className="subitems">
                                {selectedLuxBox.compound.map(c => (
                                    <a className={selectedLuxImage === c.id && 'sel'} onClick={() => {
                                        setLuxImage(c.id)
                                    }}>{c.id + 1}</a>))}
                            </div>
                        </div>
                        <button onClick={() => {
                            changeBox(setSelectedLuxBox, luxBoxes, luxBoxId, setLuxId, maxLuxBoxItems)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                 fill="FCF6ED">
                                <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z"/>
                            </svg>
                        </button>

                    </div>
                </aside>
                {/*<img src={dec3} className={'dec3'} alt=""/>*/}
                <img src={dec7} className={'dec4'} alt=""/>
            </section>

            <section className="buy">
                <img src={dec8} className={'dec1'} alt=""/>
                <div className="connect">
                    <h1>Свяжитесь с нами!</h1>
                    <a href="https://t.me/stilkorzin" className="btn">Связаться</a>
                </div>
                <img src={dec9} className={'dec2'} alt=""/>
            </section>
        </div>
    );
}

export default App;
